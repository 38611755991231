.ResultSummary__header {
  font-size: 4em !important;
}

.ResultSummary__header:first-letter {
  text-transform: capitalize !important;
}

.ResultSummary__subheader {
  font-size: 2em !important;
}

.ResultSummary__footer {
  font-size: 1.5em !important;
}

.ResultSummary__cards {
  text-align: left;
  margin: 4em 0 !important;
}

.ResultSummary__restart {
  margin-top: 1em;
}
