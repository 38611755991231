body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Attendees__header {
  line-height: 3em;
}

.AnimatedCost__cost {
  font-size: 20rem !important;
  line-height: 20rem;
}

.AnimatedCost__toggle {
  margin-top: 25px;
}

.ResultSummary__header {
  font-size: 4em !important;
}

.ResultSummary__header:first-letter {
  text-transform: capitalize !important;
}

.ResultSummary__subheader {
  font-size: 2em !important;
}

.ResultSummary__footer {
  font-size: 1.5em !important;
}

.ResultSummary__cards {
  text-align: left;
  margin: 4em 0 !important;
}

.ResultSummary__restart {
  margin-top: 1em;
}

.MeetingCost__row {
  height: 100vh;
}

.App {
  height: 100vh;
}

